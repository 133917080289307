import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/lang/:lang/courses/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/translation/v1/?type=dishes-ingredients&lang=:lang&course_id=:id";
export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang"
export const FILTERS_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&allergieprincipali=:allergies&nongraditi=:foods&diete=:diets"
export const TASTINGMENU_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&tasting_menu=:tasting_menu"

const credentials = {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
}

async function doGet(lang, id) {
    var response = await axios.get(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id }), {
        auth: credentials
    });

    return response && response.data;
}

async function doGetFilters(lang, id, allergies, foods, diets) {
    (allergies != null) ? allergies = allergies : allergies = "";
    (foods != null) ? foods = foods : foods = "";
    (diets != null) ? diets = diets : diets = "";

    var response = await axios.get(formats.formatUrl(FILTERS_ENDPOINT, { "lang": lang, "id": id, "allergies": allergies, "foods": foods, "diets": diets }), {
        auth: credentials
    });

    return response && response.data;
}

async function doGetTastingMenu(lang, id, tastingMenuId) {
    var response = await axios.get(formats.formatUrl(TASTINGMENU_ENDPOINT, { "id": id, "lang": lang, "tasting_menu": tastingMenuId }), {
        auth: credentials
    });

    return response && response.data;
}

export default {
    doGet,
    doGetFilters,
    doGetTastingMenu,
}
